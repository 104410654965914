/* eslint-disable react/prop-types */
import * as React from "react";

import {BooleanField} from 'react-admin';

import PropTypes from 'prop-types';

const FromGuideField = ({ record }) => {  
    return (<BooleanField record={record} source="fromGuide"/>);    
  }

  FromGuideField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
    };

  FromGuideField.defaultProps = { addLabel: true }

  export default FromGuideField;