import * as React from "react";
import { List, Datagrid, EmailField, DateField } from 'react-admin';
import FullNameField from './FullNameField'

export const UserList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>            
            <FullNameField label="User"/>
            <EmailField source="email" label="Email" />            
            <DateField source="dob" label="Birth date" locales="ro"/>
        </Datagrid>
    </List>
);