/* eslint-disable react/prop-types */
import * as React from "react";

import {useReference, LinearProgress} from 'react-admin';

import PropTypes from 'prop-types';

const FamilyField = ({ record }) => {
  
  if(record === undefined || record === null)
      return null;  
  
  const { loading, referenceRecord } = useReference({
        reference: 'SpecieFamily',
        id: record.familyId,
      });
    
    if (loading) {
        return <LinearProgress />
    }

    if (!referenceRecord) {
        return null
    }
    
    return record.fromGuide ? record.familyId : referenceRecord.name;
  }

  FamilyField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
    };

  FamilyField.defaultProps = { addLabel: true }

  export default FamilyField;