/* eslint-disable react/prop-types */
import * as React from "react";

import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const FullNameField = ({ record }) => {
  
    const classes = useStyles();

    return record ? (       
          <div className={classes.root}>
            <Avatar src={record.profileImgUrl} className={classes.avatar}/>
            {record.name} {record.firstName}
          </div>          
      ) : null;
  }

  FullNameField.defaultProps = { addLabel: true }

  export default FullNameField;