import * as React from "react";

import {useReference, LinearProgress} from 'react-admin';

import PropTypes from 'prop-types';

const SpeciesField = ({ record }) => {
  
    if(record === undefined || record === null)
      return null;

    const { loading, referenceRecord } = useReference({
        reference: 'Species',
        id: record.specieId,
      });
    
    if (loading) {
        return <LinearProgress />
    }

    if (!referenceRecord) {
        return null
    }
    
    return record.fromGuide ? record.specieId : referenceRecord.name;
  }

  SpeciesField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
    };

  SpeciesField.defaultProps = { addLabel: true, label: 'Species' }



  export default SpeciesField;