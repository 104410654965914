/* eslint-disable react/prop-types */
import * as React from "react";

import {useReference, LinearProgress} from 'react-admin';

import Avatar from "@material-ui/core/Avatar";
import Typography from '@material-ui/core/Typography';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const UploaderInfoField = ({ record }) => {
  
    if(record === undefined || record === null)
      return null;

    const { loading, referenceRecord } = useReference({
        reference: 'Users',
        id: record.image.uploaderId,
      });
    
    if (loading) {
        return <LinearProgress />
    }

    if (!referenceRecord) {
        return null
    }
    
    return (
        <>
          <div style={{
                display: 'flex',
                flexDirection: "row",
                
                flexWrap: 'wrap',                
            }}>
            <Avatar alt="Hello" src={referenceRecord.profileImgUrl} style={{ height: '50px', width: '50px' }}/>
            <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'left',
                    marginLeft: '10px',
                    
                }}>
                <Typography variant="subtitle2">
                    <span>{referenceRecord.name} {referenceRecord.firstName} ({referenceRecord.email})</span>
                </Typography>
                <span>
                    <>
                        <Typography variant="caption" color="textSecondary">
                            <CalendarTodayIcon fontSize="inherit" color="inherit"/>&nbsp;      
                            {Intl.DateTimeFormat('ro',
                            {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(record.image.timeStamp)}
                        </Typography>
                    </>
                </span>
                <Typography variant="caption" color="textSecondary">
                    <LocationOnIcon fontSize="inherit" color="inherit"/>&nbsp;              
                    {record.location.lat}, {record.location.lng}, {record.location.country}, {record.location.city}, {record.location.address}
                </Typography>
               
            </div>
          </div>          
        </>
      )
  }

  UploaderInfoField.defaultProps = { addLabel: true }

  export default UploaderInfoField;