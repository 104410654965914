/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { 
    TextInput, 
    BooleanInput,
    NumberInput, Labeled
} from 'react-admin';
import Lightbox from "react-awesome-lightbox";
import { 
    Box,
    Grid,
    Tab
} from '@material-ui/core';

import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';

import SpeciesField from './SpeciesField'
import FamilyField from './FamilyField'
import FromGuideField from './FromGuideField'

import Image from 'material-ui-image';

import UploaderInfoField from './UploaderInfoField';

const SubmissionEditForm = ({ record }) => {
  
    const [open, setOpen] = useState(false);

    const [tabValue, setTabValue] = useState('1');

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleTabChange = (event, newValue) => setTabValue(newValue);
      

    return(
        <Box maxWidth="50em">
            
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container direction="row" justify="center" alignItems="center" style={{backgroundColor: '#222222'}}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Image src={record.image.imageUrl} cover aspectRatio={16/9} color="black" onClick={handleClick}/>
                            {open && <Lightbox image={record.image.imageUrl} onClose={handleDialogClose} title={record.popularName}/>}
                        </Grid>                    
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>    
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>                    
                        <Grid item xs={12} sm={12} md={8}>
                            <UploaderInfoField record={record} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid container direction="row" justify="flex-end" alignItems="center">                                
                                <Grid item xs={12} sm={12} md={6}>
                                    <NumberInput source="image.points" min={0} max={99} style={{width: '7em'}} variant="outlined"/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <BooleanInput source="image.verify" label="Approved" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <TabContext value={tabValue}>
                    <TabList aria-label="Submission details" onChange={handleTabChange}>
                        <Tab label="Details" value="1" />
                        <Tab label="Description" value="2" />                    
                    </TabList>
                    <TabPanel value="1">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} spacing={2}>                                
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">                                
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Labeled label="Species">
                                            <SpeciesField label="Species" record={record}/>                                    
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Labeled label="Family">
                                            <FamilyField label="Family" record={record}/>
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>                                        
                                        <Labeled label="From guide">
                                            <FromGuideField record={record}/>
                                        </Labeled>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput source="popularName" fullWidth variant="outlined"/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput source="scientificName" fullWidth variant="outlined"/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <TextInput source="description" multiline rows="12" fullWidth variant="outlined"/>
                    </TabPanel>
                </TabContext>
                </Grid>
            </Grid>
        </Box>    
   );
}

  export default SubmissionEditForm;
