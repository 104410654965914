import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';

const Dashboard = () => (
    <Card>
        <CardHeader title="Welcome to the administration" />
        <CardContent>The APND admin panel</CardContent>
    </Card>
);

Dashboard.displayName = 'Dashboard';

export default Dashboard;