/* eslint-disable react/prop-types */
import * as React from "react";
import {
    List, 
    Edit,    
    TextField,
    BooleanField,
    DateField,
    ReferenceField,
    Datagrid,
    EditButton,
    SimpleForm, 
    FilterList, 
    FilterListItem,
    useRefresh
} from 'react-admin';

import "react-awesome-lightbox/build/style.css";
import SubmissionEditForm from './SubmissionEditForm';
import FullNameField from './FullNameField';
import SpeciesField from './SpeciesField'
import FamilyField from './FamilyField'

import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

import firebase from 'firebase/app';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = () => (
    <Card>
        <CardContent>
            <FilterList label="Approval status" icon={<ThumbUpIcon/>}>
                <FilterListItem
                    label="Approved"
                    value={{ 'image.verify': true }}
                />
                <FilterListItem
                    label="Pending"
                    value={{ 'image.verify': false }}
                />
            </FilterList>
            <FilterList label="Guide upload" icon={<MenuBookIcon/>}>
                <FilterListItem
                    label="From guide"
                    value={{ fromGuide: true }}
                />
                <FilterListItem
                    label="From picture"
                    value={{ fromGuide: false }}
                />
            </FilterList>
        </CardContent>
    </Card>
);

export const SubmissionList = (props) => {    
    
    const refresh = useRefresh();
        // Watch collection
        firebase.firestore().collection('Animals').onSnapshot(() => {
        console.log('my-collection changed!');
        // This refreshes the list view
        refresh();
    })
    
    return(
    <List {...props}         
        sort={{ field: 'image.timeStamp', order: 'DESC' }} aside={<FilterSidebar />}>
        <Datagrid>        
            <DateField label="Date" source="image.timeStamp" locales="ro" showTime/>                
            
            <ReferenceField source="image.uploaderId" reference="Users" label="User" >
                <FullNameField label="Uploader" />
            </ReferenceField>            
            <TextField source="popularName" />
            <TextField source="scientificName" />
            <SpeciesField label="Species" />
            <FamilyField label="Family" />
            <BooleanField label="Approved" source="image.verify" />
            <EditButton />
        </Datagrid>
    </List>);
};

export const SubmissionEdit = props => {   
   
    const SubmissionTitle = ({ record }) => {
                
        return <span>{record ? `${record.popularName}` : ''}</span>;
    };

    return (        
    <Edit {...props} title={<SubmissionTitle/>}>
        <SimpleForm>
            <SubmissionEditForm />
        </SimpleForm>
    </Edit>);
}
