import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { SubmissionList, SubmissionEdit } from './submissions';
import { SpeciesList } from './species';
import { SpeciesFamiliesList } from './speciesFamilies';
import { UserList } from './users';
import { FirebaseDataProvider, FirebaseAuthProvider } from 'react-admin-firebase';
import firebase from 'firebase';
import Dashboard from './Dashboard';
import { firebaseConfig } from './FIREBASE_CONFIG';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const firebaseApp = firebase.initializeApp(firebaseConfig);

const dataProvider = FirebaseDataProvider(firebaseConfig, {
    logging: true,
    app: firebaseApp,
    disableMeta: false,
    persistence: 'local',     
    lazyLoading: {
        enabled: false,
    },
    dontAddIdFieldToDoc: true,
    firestoreCostsLogger: {
        enabled: true,
    },
});

const authProvider = FirebaseAuthProvider(firebaseConfig);

const myAuthProvider = {
    ...authProvider,
    getIdentity: async () => {
        const user = await authProvider.checkAuth()
        var fullName = user.email;
        var id = '1';
        var avatar = 'avatar';
        return Promise.resolve({ id, fullName, avatar});
    },
    login: async (params) => {
        const user = await authProvider.login(params);
        // getPermissions is how when get the custom claims for the logged in user
        const claims = await authProvider.getPermissions();
        const isAdmin = claims.type == "administrator";
        if (isAdmin) {
          return user;
        }
        // Make sure user is logged out, if not an admin
        await authProvider.logout()
        throw new Error("Login error, invalid permissions");
      }
};


const App = () => (
    <Admin dashboard={Dashboard} dataProvider={dataProvider} authProvider={myAuthProvider}>
        <Resource name="Animals" list={SubmissionList} edit={SubmissionEdit} icon={MenuBookIcon} options={{ label: 'Animals' }}/>
        <Resource name="Species" list={SpeciesList} icon={LibraryBooksIcon} options={{ label: 'Species' }}/>
        <Resource name="SpecieFamily" list={SpeciesFamiliesList} icon={LibraryBooksIcon} options={{ label: 'Families' }}/>
        <Resource name="Users" list={UserList} icon={PersonIcon} options={{ label: 'Users' }}/>
    </Admin>
);

export default App;
